import styled from 'styled-components/macro'
import { ColorsMap, device } from '../../utils/Theme'

export const StyledSignInForm = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
  background-color: ${ColorsMap.white};
  box-shadow: 1px 1px 1px 1px ${ColorsMap.textDefault};
`

export const TextFlexWrapper = styled.div`
  display: flex;
  cursor: pointer;
  * {
    &:nth-child(2) {
      &:hover {
        color: ${ColorsMap.secondary};
      }
    }
  }
`
