import styled, { css } from 'styled-components/macro'
import { space, layout } from 'styled-system'
import { device, ColorsMap } from '../../../utils/Theme'
import { ProfileMenuProps } from './ProfileMenu'

export const Wrapper = styled.div<ProfileMenuProps>`
  width: 15%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  min-width: 10rem;
  ${(props) => {
    if (props.variant === 'header') {
      return css`
        width: 100%;
        margin-top: 2rem;
      `
    }
    return css`
      @media (max-width: ${device.mobile}) {
        display: none;
      }
    `
  }}
`
export const CategoryBox = styled.div<ProfileMenuProps>`
  cursor: pointer;
  margin-bottom: 0.2rem;
  ${(props) => {
    if (props.variant === 'header') {
      return css`
        margin: 0;
        color: ${ColorsMap.white};
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 3rem;
        &:hover {
          background-color: ${ColorsMap.secondary};
        }
      `
    }
    return css`
      &:hover {
        color: ${ColorsMap.brownishGrey};
      }
    `
  }}

  ${space}
  ${layout}
`
export const Line = styled.div<ProfileMenuProps>`
  margin-right: 3rem;
  height: 10rem;
  width: 2px;
  background-color: ${ColorsMap.shadowGrey};
  display: ${(props) => (props.variant === 'header' ? 'none' : 'initial')};
  @media (max-width: ${device.mobile}) {
    display: none;
  }
`
