export const TEAM = {
  gondekova: {
    name: 'Ivana Gondeková',
    email: 'ivana@tn.sintra.sk',
    phone: '+421 911 057 860',
  },
  marsalka: {
    name: 'Miloš Maršalka',
    email: 'milos@tn.sintra.sk',
    phone: '+421 907 846 200',
  },
  mayer: {
    name: 'Richard Mayer',
    email: 'richard@tn.sintra.sk',
    phone: '+421 905 361 889',
    imageSrc: '/images/mayer.jpg',
  },
  kebiskova: {
    name: 'Lenka Kebísková',
    email: 'lenka@tn.sintra.sk',
    phone: '+421 911 087 631',
  },
  sefrankova: {
    name: 'Simona Šefranková',
    email: 'simona@tn.sintra.sk',
    phone: '+421 904 853 523',
  },
  hrncar: {
    name: 'Jakub Hrnčár',
    email: 'jakub@tn.sintra.sk',
    phone: '+421 948 008 960',
  },
  pesko: {
    name: 'Adam Peško',
    email: 'adam@tn.sintra.sk',
    phone: '+421 948 008 960',
  },
  valachovicova: {
    name: 'Terézia Valachovičová',
    email: 'terezia@tn.sintra.sk',
    phone: '+421 910 736 943',
  }
}

export const CONTACTS = [
  {
    title: ['E-shop-contact', true],
    contacts: [TEAM.gondekova],
  },
  {
    title: 'Hummel',
    contacts: [TEAM.marsalka],
  },
  {
    title: 'Pure2Improve',
    contacts: [TEAM.pesko],
  },
  {
    title: 'Icepeak | Luhta | Rukka',
    contacts: [TEAM.kebiskova, TEAM.sefrankova],
  },
  {
    title: 'Rider',
    contacts: [TEAM.sefrankova],
  },
  {
    title: 'Golf',
    contacts: [TEAM.mayer, TEAM.valachovicova],
  },
] as const

export const BRAND_SECONDARY_CONTACTS = {
  luhta: TEAM.sefrankova,
  icepeak: TEAM.sefrankova,
  rukka: TEAM.sefrankova,
}

export const BRAND_CONTACTS = {
  hummel: TEAM.marsalka,
  'pure-2-improve': TEAM.pesko,
  luhta: TEAM.kebiskova,
  icepeak: TEAM.kebiskova,
  rukka: TEAM.kebiskova,
  rider: TEAM.sefrankova,
  'cobra-puma-golf': TEAM.mayer,
  'clicgear': TEAM.mayer,
  'rovic': TEAM.mayer,
  'volvik': TEAM.mayer,
  'lignum-tee': TEAM.mayer,
  'axglo': TEAM.mayer,
  'one': TEAM.mayer,
  'sniper-golf': TEAM.mayer,
  'pure-2-improve-golf': TEAM.mayer,
  'spalding': TEAM.mayer,
}
