import React from 'react'
import { LayoutProps, SpaceProps } from 'styled-system'

import { StyledInput, Wrapper, IconChild, Label } from './InputTextStyles'

export interface InputTextprops extends LayoutProps, SpaceProps {
  placeHolder?: string
  label?: React.ReactNode
  name?: string
  type?: string
  id?: string
  value?: string
  onChange?: (e: any) => any
  invalid?: boolean
  disabled?: boolean
}

export const InputText: React.FC<InputTextprops> = ({
  placeHolder,
  children,
  label,
  type,
  id,
  name,
  value,
  onChange,
  invalid = false,
  disabled = false,
  ...props
}) => (
  <Wrapper {...props}>
    <Label htmlFor={id}>{label}</Label>
    <StyledInput
      id={id}
      type={type}
      name={name}
      placeholder={placeHolder}
      value={value}
      onChange={onChange}
      invalid={invalid}
      disabled={disabled}
    />
    <IconChild {...props}>{children}</IconChild>
  </Wrapper>
)
