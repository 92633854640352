import React, { useState, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useDispatch } from 'react-redux'
import { Button } from '../../Button'
import Icons from '../../Icons'
import { Text } from '../../Text'
import { device, ColorsMap } from '../../../utils/Theme'
import useTranslation from 'next-translate/useTranslation'

import {
  Wrapper,
  MainPart,
  Name,
  Line,
  Separator,
  DynamicName,
} from './ProfileSubHeaderStyles'
import { Cookie } from '../../../utils/Helpers'

import {
  productPrint,
  categoryPrint,
  brandFilter,
} from '../../../redux/actions'
import router from 'next/router'

export type VariantType = 'default' | 'slider'

export interface ProfileSubHeaderProps {
  name?: string
}

export const ProfileSubHeader: React.FC<ProfileSubHeaderProps> = ({ name }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')

  return (
    <>
      <Wrapper>
        <MainPart>
          <Text textColor="primary" fontWeight="bold" fontSize="xl">
            {t('user-greeting', { name })}
          </Text>
          <Button
            onClick={() => {
              Cookie.set('token', null, 0)
              router.push('/')
            }}
            variant="default"
            contentAlign="start"
            m="0.5rem 0"
          >
            {t('sign-out')}
          </Button>
        </MainPart>

        <Line />
        <MainPart>
          <DynamicName>
            <Text textColor="primary" fontWeight="bold" mb="0.5rem">
              {t('follow-us-text')}
            </Text>
          </DynamicName>
          <div>
            <a href="https://www.facebook.com/sintrasportsk/" target="_blank">
              <Icons.Facebook size="xl" mr="0.5rem" />
            </a>
            <a href="https://www.instagram.com/sintrasport/" target="_blank">
              <Icons.Instagram size="xl" />
            </a>
          </div>
        </MainPart>
        <Line />

        <MainPart>
          <Text textColor="primary" fontWeight="bold">
            {t('have-questions')}
          </Text>
          <Text mt="0.5rem" mb="0.1rem">
            <Icons.Phone size="m" color={ColorsMap.primary} mr="0.5rem" />
            +421 911 057 860
          </Text>
          <Text>
            <Icons.Envelope size="m" color={ColorsMap.primary} mr="0.5rem" />
            ivana@tn.sintra.sk
          </Text>
        </MainPart>
      </Wrapper>
      <Separator />
    </>
  )
}
