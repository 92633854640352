import styled, { css } from 'styled-components/macro'
import { space, layout } from 'styled-system'

import { device, FontSizeMap, ColorsMap, FontWeightMap } from '@/utils/Theme'

import { HeaderProps } from './Header'

export const HeaderOuterWrapper = styled.header``

export const HeaderInfo = styled.div<{ mobileOnly?: boolean }>`
  text-align: center;
  background: ${ColorsMap.primary};
  padding: 0.75rem 2rem;
  color: ${ColorsMap.white};

  @media (max-width: ${device.tablet}) {
    display: none;
  }

  ${({ mobileOnly }) =>
    mobileOnly &&
    css`
      display: none;

      @media (max-width: ${device.tablet}) {
        display: block;
      }
    `}
`

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0 7%;

  @media (max-width: ${device.mobile}) {
    margin-bottom: 2rem;
  }
`

export const Logo = styled.div`
  min-width: 250px;
  order: 2;
  align-self: center;
  padding: 1rem 0rem;
  margin-right: 2rem;
  cursor: pointer;

  @media (max-width: ${device.tablet}) {
    min-width: 175px;
    max-width: 175px;
    margin-right: 0;
    padding: 1.75rem 0rem;
  }
  > a {
    display: block;
  }
`

export const WrapperButtons = styled.div`
  display: flex;
  order: 3;

  @media (max-width: ${device.mobileS}) {
    display: none;
  }
  @media (max-width: ${device.tablet}) {
    justify-self: flex-end;
  }
`

export const BurgerButtonsWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 2rem;
  right: 2rem;

  @media (min-width: ${device.mobileS}) {
    display: none;
  }
`

export const CategoryWrapper = styled.div`
  @media (min-width: ${device.mobileS}) {
    display: none;
  }
`
export const HeaderFixed = styled.div`
  @media (max-width: ${device.tablet}) {
    margin-bottom: 9rem;
  }
`

type UpperPartProps = { backgroundGrey?: boolean }
export const UpperPart = styled.div<UpperPartProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 280px;
  @media (max-width: ${device.tablet}) {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    ${(props) =>
      props.backgroundGrey
        ? css`
            background-color: ${ColorsMap.background};
            box-shadow: -20px 3px 15px ${ColorsMap.lightGrey};
          `
        : css`
            background-color: ${ColorsMap.white};
          `}

    position: fixed;
    width: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 0 7%;
    padding-bottom: 1rem;
    flex-wrap: wrap;
  }
`
export const InputTextWrapper = styled.form`
  width: 100%;
  order: 2;

  @media (min-width: ${device.tablet}) {
    max-width: 32rem;
  }
  @media (max-width: ${device.tablet}) {
    grid-row: 2/3;
    grid-column: 1/4;
  }
`
export const BurgerWrapper = styled.div<HeaderProps>`
  z-index: 1;
  display: none;
  min-width: 2.3rem;
  width: fit-content;
  @media (max-width: ${device.tablet}) {
    display: ${(props) => (props.categories ? 'flex' : 'none')};
    margin-top: 2.2rem;
    order: 1;
    margin: 0 1.5%;
    margin-right: 0.25rem;
    position: relative;
  }
  @media (max-width: ${device.tablet}) {
    display: flex;
  }
`

export const BrandMenu = styled.div`
  height: 5rem;
  width: 100%;
  margin: 0;

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`
export const BrandMenuInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
    height: 100%;
`

export const MainButtonDisplay = styled.div`
  max-width: 6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;

  &:hover {
    color: ${ColorsMap.brownishGrey};
  }

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`

export const BurgerInCartWrapper = styled.div`
  @media (min-width: ${device.mobileS}) {
    display: none;
  }
`

export const GOLFDropdownWrapper = styled.div`
  width: 8rem;
`

export const Brands = styled.nav`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 2rem;
`

export const BurgerContent = styled.div`
  display: flex;
  justify-content: center;
`

export const MainButton = styled.div`
  display: flex;
  margin: auto 1rem;
  @media (max-width: ${device.tablet}) {
    margin: auto 0;
  }

  @media (max-width: ${device.mobileS}) {
    margin: auto 0.5rem;
  }
`

export const LogoImg = styled.img<{ hover?: boolean; fluid?: boolean }>`
  height: ${(props) => (props.fluid ? 'auto' : '100%')};
  transition: opacity 0.3s;
  width: 100%;
  ${(props) =>
    props.hover &&
    css`
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    `};
  ${layout}
  ${space}
`

type SignInBoxProps = { show: boolean }
export const SignInBoxWrapper = styled.div<SignInBoxProps>`
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  width: 100%;
  z-index: 2;
  display: ${({ show }) => (show ? 'initial' : 'none')};
  @media (min-width: ${device.mobile}) {
    display: none;
  }
`

export const SignInBoxBackground = styled.div<SignInBoxProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: black;
  opacity: 0.2;
  display: ${({ show }) => (show ? 'initial' : 'none')};
  @media (min-width: ${device.mobile}) {
    display: none;
  }
`
export const Close = styled.div`
  position: absolute;
  top: 0;
  right: 1rem;
  font-size: ${FontSizeMap.xxl};
  transform: rotate(45deg);
  cursor: pointer;

  &:hover {
    color: ${ColorsMap.fontHover};
  }
`

export const CategoriesWrapper = styled.div<HeaderProps>`
  display: ${(props) => (props.categories ? 'initial' : 'none')};
`

export const ProfileMenuWrapper = styled.div<HeaderProps>`
  @media (min-width: ${device.mobile}) {
    display: none;
  }
  display: ${(props) => (props.profileMenu ? 'initial' : 'none')};
`

export const LanguageWrapper = styled.div`
  background-color: ${ColorsMap.white};
  box-shadow: -20px 20px 20px 0px rgba(0, 0, 0, 0.06);
  padding: 0.5rem;
  @media (max-width: ${device.mobileS}) {
    background-color: ${ColorsMap.primary};
    color: ${ColorsMap.white};
    margin-top: 0.5rem;
    margin-left: -2rem;
    * {
      color: ${ColorsMap.white};
    }
  }
`

export const LanguageImg = styled.img`
  display: block;
  height: auto;
  min-width: 1.5rem;

  &:first-child {
    margin-left: 0.65rem;
  }
`
export const Anchor = styled.a`
  text-decoration: none;
  ${layout}
  ${space}
`

// TEMP
export const EShopButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-weight: ${FontWeightMap.extrabold};
    font-size: ${FontSizeMap.l};
    height: 100%;
    text-transform: uppercase;
    background: ${ColorsMap.error};
    color: ${ColorsMap.white};
    padding: 0 2rem;
    max-width: 160px;
    word-break: keep-all;
    
    @media (max-width: ${device.tablet}) {
        height: 3rem;
        max-width: unset;
    }
`

// TEMP
export const GolfDropDownIconWrapper = styled.div`
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
`
