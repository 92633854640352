import { retrieve } from '@/utils/Helpers'

export const getUserData = ({ token = null }) => {
  return (dispatch) => {
    return retrieve('api/user', 'GET', token)
      .then((res) => res.json())
      .then((res) => {
        dispatch(userAction({ payload: res }))
      })
  }
}

export const userAction = ({ payload }) => {
  return {
    type: 'USER_DATA',
    payload,
  }
}
