import React, { useState } from 'react'

import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'

import Icons from '@/components/Icons'
import { Button } from '../../Button'
import { InputText } from '../../InputText'
import { Text } from '../../Text'

import { ColorsMap } from '@/utils/Theme'
import { retrieve } from '@/utils/Helpers'

import { FooterWrapper, StyledFooter, EndFooter, Section, Box, Logo, Info, LinksWrapper } from './FooterStyles'
import { CONTACTS } from '@/static/General'

export const Footer: React.FC = () => {
  const [state, setState] = useState({
    email: '',
  })

  const { t } = useTranslation('common')

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (state.email != '') {
      /* TODO */
      await retrieve('api/newsletter', 'POST', { email: state.email })
        .then((res) => res.json())
        .then((res) => {
          setState({ ...state, email: '' })
          // router.push('/')
        })
        .catch((e) => {})
    }
  }
  return (
    <>
      <FooterWrapper>
        <StyledFooter>
          <div>
            <Logo>
              <Image src="/images/logo.svg" width="180px" height="40px" />
            </Logo>
            <div>
              <a href="https://www.facebook.com/sintrasportsk/" target="_blank">
                <Icons.Facebook size="xl" mr="0.5rem" />
              </a>
              <a href="https://www.instagram.com/sintrasport/" target="_blank">
                <Icons.Instagram size="xl" />
              </a>
            </div>
          </div>
          <Info>
            <Section>
              <Text textColor={ColorsMap.primary} fontSize="l" fontWeight="bold" mb="1rem">
                {t('contact')}
              </Text>
              <LinksWrapper>
                {CONTACTS.map((section) => (
                  <div>
                    <Text textColor={ColorsMap.primary} fontSize="m" fontWeight="bold">
                      {Array.isArray(section.title) && section.title.length >= 2 && section.title[1] === true
                        ? t(section.title[0] as string)
                        : (section.title as string)}
                      :
                    </Text>
                    {section.contacts.map((contact) => (
                      <div>
                        {contact.name}, <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                      </div>
                    ))}
                  </div>
                ))}
              </LinksWrapper>
            </Section>
            <Section>
              <Text textColor={ColorsMap.primary} fontSize="l" fontWeight="bold" mb="1rem">
                {t('information')}
              </Text>
              <LinksWrapper>
                <Link href="/about-us">{t('about-us')}</Link>
                <Link href="/contact">{t('contact')}</Link>
                <Link href="/downloads">{t('for-download')}</Link>
                <Link href="/terms">{t('terms-of-usage')}</Link>
                <Link href="/gdpr">{t('privacy-policy')}</Link>
              </LinksWrapper>
            </Section>
            <Section>
              <Text textColor={ColorsMap.primary} fontSize="l" fontWeight="bold" mb="1rem">
                {t('subscribe-newsletter-title')}
              </Text>
              <Text mb="1rem">{t('subscribe-newsletter-content')}</Text>
              <form onSubmit={handleSubmit}>
                <InputText
                  type="email"
                  name="footerEmail"
                  id="footerEmail"
                  onChange={handleChange}
                  placeHolder={t('email-placeholder')}
                  width="100%"
                >
                  <Button type="submit" variant="horizontalRad" colorVariant="primary">
                    {t('subscribe')}
                  </Button>
                </InputText>
              </form>
            </Section>
          </Info>
        </StyledFooter>
        <EndFooter>
          <Text textColor={ColorsMap.white}>
            {t('copyright')}
            <Link href="https://pomestudio.sk/" passHref>
              <Text as="a" textColor={ColorsMap.white}>
                POME STUDIO
              </Text>
            </Link>
          </Text>
        </EndFooter>
      </FooterWrapper>
    </>
  )
}

