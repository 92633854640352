import styled, { css } from 'styled-components/macro'
import { space, layout } from 'styled-system'

import { ButtonProps } from './Button'

import { ColorsMap, FontWeightMap } from '@/utils/Theme'

const ButtonStyleVariations = {
  DEFAULT: 'default',
  HORIZONTALRAD: 'horizontalRad',
  VERTICALRAD: 'verticalRad',
}
const buttonColorVariantVariations = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WHITE: 'white',
  PRIMARYWHITE: 'primaryWhite',
  DEFAULTWHITE: 'defaultWhite',
  TRANSPARENT: 'transparent',
}

const getButtonStyle = ({ variant }: ButtonProps) => {
  switch (variant) {
    case ButtonStyleVariations.DEFAULT:
      return css`
        display: flex;
        color: ${ColorsMap.textDefault};
        align-items: center;
        &:hover {
          color: ${ColorsMap.brownishGrey};
        }
      `
    case ButtonStyleVariations.HORIZONTALRAD:
      return css`
        border: 1px solid;
        padding: 0.9rem 2rem;
        border-radius: 5rem;
        font-weight: ${FontWeightMap.bold};
      `
    case ButtonStyleVariations.VERTICALRAD:
      return css`
        border: 1px solid;
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 5rem;
      `
    default:
      return ''
  }
}
const getButtonColorVariant = ({ colorVariant }: ButtonProps) => {
  switch (colorVariant) {
    case buttonColorVariantVariations.PRIMARY:
      return css`
        color: ${ColorsMap.white};
        background-color: ${ColorsMap.primary};
        &:hover {
          background-color: ${ColorsMap.secondary};
        }
      `
    case buttonColorVariantVariations.SECONDARY:
      return css`
        color: ${ColorsMap.white};
        background-color: ${ColorsMap.secondary};
        border-color: ${ColorsMap.white};
        &:hover {
          background-color: ${ColorsMap.primary};
        }
      `
    case buttonColorVariantVariations.WHITE:
      return css`
        color: ${ColorsMap.textDefault};
        background-color: ${ColorsMap.white};
        border-color: ${ColorsMap.borwnishGrey};
      `
    case buttonColorVariantVariations.DEFAULTWHITE:
      return css`
        color: ${ColorsMap.borwnishGrey};
        background-color: ${ColorsMap.white};
        border-color: ${ColorsMap.primary};
        &:hover {
          color: ${ColorsMap.white};
          background-color: ${ColorsMap.primary};
        }
      `
    case buttonColorVariantVariations.PRIMARYWHITE:
      return css`
        color: ${ColorsMap.primary};
        background-color: ${ColorsMap.white};
        border-color: ${ColorsMap.primary};
        &:hover {
          color: ${ColorsMap.white};
          background-color: ${ColorsMap.primary};
        }
      `

    case buttonColorVariantVariations.TRANSPARENT:
      return css`
        color: ${ColorsMap.white};
        background: rgba(0, 0, 0, 20%);
        border-color: ${ColorsMap.white};
      `
    default:
      return ''
  }
}

export const StyledButton = styled.button<ButtonProps>`
  position: relative;
  display: flex;
  width: fit-content;
  justify-content: ${(props) => {
    return props.contentAlign ? props.contentAlign : 'center'
  }};
  align-items: center;
  padding: 0;
  ${getButtonStyle}
  ${getButtonColorVariant}
  text-align: ${(props) => {
    return props.contentAlign ? props.contentAlign : 'center'
  }};
  border: ${(props) => {
    return props.borderNone ? 'none' : ''
  }};
  ${(props) => {
    return props.uppercase && 'text-transform: uppercase'
  }};
  ${space}
  ${layout}
`

