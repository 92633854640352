import styled, { css } from 'styled-components/macro'
import { layout, space } from 'styled-system'

import { ColorsMap } from '@/utils/Theme'

import { InputTextprops } from './InputText'

export const Wrapper = styled.div<InputTextprops>`
  width: ${(props) => (props.width ? props.width : '25rem')};
  position: relative;
  ${layout}
  ${space}
`

type StyledInputProps = { invalid: boolean }
export const StyledInput = styled.input<StyledInputProps>`
  outline: none;
  border: 1px solid ${ColorsMap.textDefault};
  width: 100%;
  height: 3rem;
  border-radius: 1.9rem;
  padding: 0 1.5rem;
  position: relative;
  color: ${ColorsMap.black};

  ${({ invalid }) =>
    invalid &&
    css`
      border-color: ${ColorsMap.error};
    `}
  ${layout}
  ${space}
`
export const IconChild = styled.div<InputTextprops>`
  position: absolute;
  top: 1.5rem;
  transform: translate(0, -50%);
  right: 0;
`
export const Label = styled.label<InputTextprops>`
  color: ${ColorsMap.textDefault};
  float: left;
  margin: 1rem 0;
`
