import React, { useState, useEffect } from 'react'

import Link from 'next/link'

import { useDispatch } from 'react-redux'
import { SpaceProps, LayoutProps } from 'styled-system'
import { useRouter } from 'next/router'
import { ColorsMap, FontWeightMap } from '../../../utils/Theme'
import { Wrapper, CategoryBox, Line } from './ProfileMenuStyles'
import useTranslation from 'next-translate/useTranslation'

type ProfileMenuVariant = 'header' | 'default'
export interface ProfileMenuProps extends SpaceProps, LayoutProps {
  categoryBoxName?: string
  variant: ProfileMenuVariant
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({ variant }) => {
  const { t } = useTranslation('common')

  const router = useRouter()
  const activePage = router.pathname

  useEffect(() => {
    if (document.getElementById(variant + activePage)) {
      if (variant === 'header') {
        document.getElementById(variant + activePage).style.color =
          ColorsMap.white
        document.getElementById(variant + activePage).style.backgroundColor =
          ColorsMap.secondary
      } else {
        document.getElementById(variant + activePage).style.color =
          ColorsMap.primary
      }

      document.getElementById(
        variant + activePage
      ).style.fontWeight = `${FontWeightMap.bold}`
    }
  }, [activePage])

  return (
    <>
      <Wrapper variant={variant}>
        <Link href="/profile/orders">
          <CategoryBox
            id={`${variant}/profile/orders`}
            activePage={variant + activePage}
            mb="2rem"
            variant={variant}
          >
            {t('my-orders')}
          </CategoryBox>
        </Link>
        {/* <Link href="/profile/addresses">
          <CategoryBox
            id={`${variant}/profile/addresses`}
            activePage={variant + activePage}
            variant={variant}
          >
            {t('my-addresses')}
          </CategoryBox>
        </Link> */}
        <Link href="/profile/password">
          <CategoryBox
            id={`${variant}/profile/password`}
            activePage={variant + activePage}
            variant={variant}
          >
            {t('change-password')}
          </CategoryBox>
        </Link>
      </Wrapper>
      <Line variant={variant} />
    </>
  )
}
