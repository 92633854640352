import React from 'react'
import styled, { css } from 'styled-components/macro'
import { LayoutProps, SpaceProps, layout, space } from 'styled-system'

import { device, ColorsMap, FontSizeMap } from '../../utils/Theme'

export interface LoaderProps extends LayoutProps, SpaceProps {}

const StyledLoader = styled.div`
  border: 0.25rem solid ${ColorsMap.lightGrey};
  border-radius: 50%;
  border-top: 0.25rem solid ${ColorsMap.primary};
  width: 2rem;
  height: 2rem;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  ${space}
  ${layout}
`

export const Loader: React.FC<LoaderProps> = ({ ...props }) => {
  return <StyledLoader {...props} />
}
