import React from 'react'
import styled from 'styled-components/macro'
import { space, layout } from 'styled-system'
import { ColorsMap } from '@/utils/Theme'

const InCardWrapper = styled.span`
  position: absolute;
  min-width: 1.025rem;
  height: 1.025rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${ColorsMap.primary};
  background-color: ${ColorsMap.primary};
  border-radius: 0.5rem;
  font-size: 0.75rem;
  padding: 0.125rem;
  top: 0rem;
  right: -0.125rem;
  transform: translateX(100%);
  color: ${ColorsMap.white};
  ${space}
  ${layout}
`

const ProductCount: React.FC = ({ children }) => {
  let count = children
  if (children > 99) {
    count = '99+'
  }
  return <InCardWrapper>{count}</InCardWrapper>
}

export default ProductCount
