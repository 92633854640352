import { retrieve } from '@/utils/Helpers'

export const addToCartAction = ({ payload }) => {
  return {
    type: 'ADD_TO_CART',
    payload,
  }
}
export const setCartAction = ({ payload }) => {
  return {
    type: 'SET_CART',
    payload,
  }
}
export const purgeCartAction = () => {
  return {
    type: 'PURGE_CART',
  }
}
export const showCartProductsAction = ({ payload }) => {
  return {
    type: 'SHOW_CART_PRODUCTS',
    payload,
  }
}

export const removeCartProductAction = ({ payload }) => {
  return {
    type: 'REMOVE_ALL_FROM_CART',
    payload,
  }
}

export const removeFromCartAction = ({ payload }) => {
  return {
    type: 'REMOVE_FROM_CART',
    payload,
  }
}

export const cartInitial = () => {
  return {
    type: 'CART_INITIAL',
  }
}

export const precalculateOrderDetailsAction = ({ payload }) => {
  return {
    type: 'PRECALCULATE_ORDER_DETAILS',
    payload,
  }
}

export const getOrderDetailsAction = ({ payload }) => {
  return {
    type: 'GET_ORDER_DETAILS',
    payload,
  }
}

export const getOrderDetails = ({
  products,
  codes = null,
  shipping_method = null,
  payment_method = null,
  token = null,
}) => {
  return (dispatch) => {
    dispatch(precalculateOrderDetailsAction({ payload: products }))
    return retrieve('api/cart', 'POST', token, {
      products,
      codes,
      shipping_method,
      payment_method,
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(getOrderDetailsAction({ payload: res }))
      })
  }
}

