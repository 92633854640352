import styled from 'styled-components/macro'
import {
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  LayoutProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from 'styled-system'
import { color, ColorProps, type, TypeProps } from '../../utils/Theme'

export interface TextProps
  extends SpaceProps,
    LayoutProps,
    ColorProps,
    FontWeightProps,
    TextAlignProps,
    FontSizeProps,
    TypeProps {
  as?: string
  children?: React.ReactChild | React.ReactChild[]
  textTransform?: string
}

/**
 * Text component
 */
export const Text = styled('p').withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['fontSize', 'fontWeight', 'type', 'textColor', 'textAlign', 'm', 'mb', 'mt'].includes(prop),
})<TextProps>`
  ${fontWeight};
  ${fontSize};
  ${type};
  ${textAlign};
  ${space};
  ${color};
`

Text.defaultProps = {
  fontSize: 'm',
  fontWeight: 'normal',
  type: 'default',
}

