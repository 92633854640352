import React, { useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useRouter } from 'next/router'

import Icons from '@/components/Icons'
import { Button } from '@/components/Button'
import { ColorsMap } from '@/utils/Theme'
import { Text } from '@/components/Text'
import {
  StyledCategories,
  CategoryBox,
  CategoryBoxWrapperUl,
  CategoryBoxWrapperLi,
  IconWrapper,
  CategoryMinus,
  CategoryChevron,
  Separator,
} from '@/components/Categories/CategoriesStyles'

import { IconSize as IconSizeObject } from '../Icons/Icons'

export type CategoryVariant = 'header' | 'default'

export interface CategoryProps {
  categoryParentIdProp?: number
  categories: any
  categoryFilter?: string
  variant: CategoryVariant
  page: string
  slug?: string
}

export interface IconProps {
  hasNoChildren?: boolean
}

export const Categories: React.VFC<CategoryProps> = ({ categories, variant, page }) => {
  const router = useRouter()
  const { t } = useTranslation('common')

  const [category, setCategory] = useState<string | null>(router.query.category)

  const { page: _, ...excludedQuery } = router.query

  useEffect(() => {
    setCategory(router.query.category)
    const activeCategory = categories.find((category) => category.slug == router.query.category)

    if (activeCategory) {
      const getParents = (activeCategory) => {
        categories.map((category) => {
          if (category.id == activeCategory.parent_id) {
            const categoryMinus = document.getElementById(`${variant}minus${category.id}`)
            const categoryChevron = document.getElementById(`${variant}ChevronDown${category.id}`)
            document.getElementById(variant + category.id).style.display = 'initial'

            categoryChevron.style.display = 'none'
            categoryMinus.style.display = 'flex'
            getParents(category)
          }
        })
      }
      getParents(activeCategory)
    }
  }, [router.query.category])

  const getAllChildren = (category) => {
    document.getElementById(`${variant}ChevronDown${category.id}`).style.display = 'flex'
    document.getElementById(`${variant}minus${category.id}`).style.display = 'none'

    categories.map((item) => {
      if (category.id === item.parent_id) {
        document.getElementById(variant + item.id).style.display = 'none'
        getAllChildren(item)
      }
    })
  }

  const displayCategories = (categoryData) => {
    const categoryMinus = document.getElementById(`${variant}minus${categoryData.id}`)
    const categoryChevron = document.getElementById(`${variant}ChevronDown${categoryData.id}`)

    if (document.getElementById(variant + categoryData.id).style.display == 'initial') {
      document.getElementById(variant + categoryData.id).style.display = 'none'

      categoryChevron.style.display = 'flex'
      categoryMinus.style.display = 'none'
    } else {
      document.getElementById(variant + categoryData.id).style.display = 'initial'

      categoryChevron.style.display = 'none'
      categoryMinus.style.display = 'flex'
    }
  }
  const icon = (categoryData) => {
    let hasChildren = false
    categories.forEach((item) => {
      if (item.parent_id === categoryData.id) {
        hasChildren = true
      }
    })

    let iconSize: keyof typeof IconSizeObject = 'm'

    if (variant === 'header') {
      iconSize = 'l'
    }

    if (hasChildren) {
      return (
        <IconWrapper variant={variant}>
          <CategoryMinus id={`${variant}minus${categoryData.id}`}>
            <Icons.Minus size={iconSize} />
          </CategoryMinus>
          <CategoryChevron id={`${variant}ChevronDown${categoryData.id}`}>
            <Icons.ChevronDown size={iconSize} />
          </CategoryChevron>
        </IconWrapper>
      )
    }
    return (
      <IconWrapper variant={variant}>
        <CategoryMinus id={`${variant}minus${categoryData.id}`} />
        <CategoryChevron id={`${variant}ChevronDown${categoryData.id}`} hasNoChildren />
      </IconWrapper>
    )
  }

  const children = (categoryId) => (
    <CategoryBoxWrapperUl id={variant + categoryId} categoryParentIdProp={categoryId} variant={variant}>
      {categories.map((categoryData, i) => {
        if (categoryData.parent_id == categoryId && categoryData.products_count > 0) {
          return (
            <CategoryBoxWrapperLi key={i}>
              <CategoryBox
                categoryFilter={category}
                categoryParentIdProp={categoryData.parent_id}
                variant={variant}
                slug={categoryData.slug}
              >
                <span onClick={() => displayCategories(categoryData)}>{icon(categoryData)}</span>
                <Link
                  href={{
                    pathname: page,
                    query: {
                      ...excludedQuery,
                      category: categoryData.slug,
                    },
                  }}
                  shallow
                >
                  {categoryData.name}
                </Link>
                <Separator />
              </CategoryBox>
              {children(categoryData.id)}
            </CategoryBoxWrapperLi>
          )
        }
      })}
    </CategoryBoxWrapperUl>
  )
  return (
    <StyledCategories variant={variant}>
      <CategoryBoxWrapperUl variant={variant}>
        <Button
          onClick={() => {
            router.push(
              {
                pathname: page,
                query: {
                  ...excludedQuery,
                  category: null,
                },
              },
              undefined,
              { shallow: true }
            )
          }}
        >
          <CategoryBox variant={variant}>
            <Text fontWeight="bold" textColor={ColorsMap.secondary}>
              {t('show-all')}
            </Text>
          </CategoryBox>
        </Button>
      </CategoryBoxWrapperUl>
      {categories.map((categoryData, i) => {
        if (categoryData.parent_id == null && categoryData.products_count > 0) {
          return (
            <CategoryBoxWrapperUl variant={variant} key={i} categoryParentIdProp={categoryData.parent_id}>
              <CategoryBox
                categoryFilter={category}
                categoryParentIdProp={categoryData.parent_id}
                variant={variant}
                slug={categoryData.slug}
              >
                <span
                  onClick={() => {
                    displayCategories(categoryData)
                  }}
                >
                  {icon(categoryData)}
                </span>
                <Link
                  href={{
                    pathname: page,
                    query: {
                      ...excludedQuery,
                      category: categoryData.slug,
                    },
                  }}
                  shallow
                >
                  {categoryData.name}
                </Link>
              </CategoryBox>
              {children(categoryData.id)}
            </CategoryBoxWrapperUl>
          )
        }
      })}
    </StyledCategories>
  )
}
