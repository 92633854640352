import React, { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { Button } from '../Button'

import { ColorsMap } from '@/utils/Theme'
import { Cookie, retrieve } from '@/utils/Helpers'

import { InputText } from '@/components/InputText'
import { Text } from '@/components/Text'
import { StyledSignInForm, TextFlexWrapper } from './SignInBoxStyles'

export interface SignInBoxProps {
  variant?: string
}

export const SignInBox: React.VFC<SignInBoxProps> = ({ variant }) => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const [state, setState] = useState({
    email: '',
    password: '',
  })

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (state.email != '' && state.password != '') {
      await retrieve('api/auth/login', 'POST', null, {
        email: state.email,
        password: state.password,
      })
        .then((res) => {
          if (res.status === 403) {
            throw new Error('Unauthorized.')
          }
          return res.json()
        })
        .then((res) => {
          Cookie.setText('token', res.data.token, 7)
          router.push('/')
        })
        .catch((e) => {
          document.getElementById(
            `${variant}_login_password`
          ).style.borderColor = ColorsMap.error
          document.getElementById(`${variant}_login_email`).style.borderColor =
            ColorsMap.error
        })
    } else {
      setState(() => ({
        email: '',
        password: '',
      }))
    }
  }

  return (
    <StyledSignInForm onSubmit={handleSubmit}>
      <Text
        textColor={ColorsMap.primary}
        fontSize="l"
        fontWeight="bold"
        m="1rem 0"
      >
        {t('sign-in')}
      </Text>
      <InputText
        placeHolder={t('email-placeholder')}
        label={t('email')}
        name="email"
        type="email"
        id={`${variant}_login_email`}
        width="22rem"
        m="1rem 0"
        onChange={handleChange}
        value={state.email}
      />
      <InputText
        placeHolder={t('password-placeholder')}
        type="password"
        name="password"
        id={`${variant}_login_password`}
        label={t('password')}
        width="22rem"
        onChange={handleChange}
        value={state.password}
      />
      <Button
        type="submit"
        variant="horizontalRad"
        colorVariant="primary"
        mt="3rem"
      >
        <Text fontWeight="bold">{t('sign-in')}</Text>
      </Button>
      {/* <Text
        textColor={ColorsMap.primary}
        fontSize="l"
        fontWeight="bold"
        m="1rem 0"
      >
        {t('or')}
      </Text>
      <Button variant="horizontalRad" colorVariant="white">
        {t('facebook-login')}
      </Button> */}
      <TextFlexWrapper>
        <Text
          textColor={ColorsMap.textDefault}
          fontSize="l"
          mt="2rem"
          mr="1rem"
          mb="1rem"
        >
          {t('new-member')}
        </Text>
        <Link href="/register/" passHref>
          <Text
            as="a"
            textColor={ColorsMap.primary}
            fontSize="l"
            fontWeight="bold"
            mt="2rem"
            mb="1rem"
          >
            {t('register-now')}
          </Text>
        </Link>
      </TextFlexWrapper>
    </StyledSignInForm>
  )
}
