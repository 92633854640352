import styled, { css } from 'styled-components/macro'
import { space } from 'styled-system'
import { ColorsMap } from '@/utils/Theme'

import { CategoryProps, IconProps } from './Categories'

const catgVariants = {
  HEADER: 'header',
}

const getVariantStyles = ({ variant }: CategoryProps) => {
  if (variant === catgVariants.HEADER) {
    return css`
      height: 3rem;
      position: relative;
      &:hover {
        color: ${ColorsMap.secondary};
      }
    `
  }
  return ''
}

export const StyledCategories = styled.div`
  min-width: 17rem;
  width: 21%;
  ${(props) => {
    if (props.variant === catgVariants.HEADER) {
      return css`
        width: 100%;
      `
    }
    return null
  }}
`

export const CategoryBox = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;

  ${(props) => {
    return props.isActive
      ? css`
          color: ${ColorsMap.brownishGrey};
        `
      : css`
          color: ${ColorsMap.textDefault};
        `
  }}
  ${(props) => {
    if (props.variant === catgVariants.HEADER) {
      return css`
        margin-left: 3rem;
      `
    }
  }}
  ${(props) => {
    if (props.variant === catgVariants.HEADER) {
      return props.isActive
        ? css`
            color: ${ColorsMap.secondary};
          `
        : css`
            color: ${ColorsMap.white};
          `
    }
  }}
  &:hover {
    color: ${ColorsMap.brownishGrey};
  }
  ${getVariantStyles}
  ${space}
`
export const IconWrapper = styled.div<{ variant: string }>`
  ${(props) => {
    if (props.variant === catgVariants.HEADER) {
      return css`
        display: flex;
        justify-content: center;
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 50%;
        right: 1rem;
        transform: translate(0, -50%);
      `
    }
  }}
`

type CategoryBoxWrapperUlProps = {
  categoryParentIdProp?: number
  variant: 'header'
}
export const CategoryBoxWrapperUl = styled.ul<CategoryBoxWrapperUlProps>`
  position: relative;
  list-style-type: none;
  padding: 0;
  width: 100%;
  ${(props) =>
    props.categoryParentIdProp == null
      ? null
      : css`
          display: none;
        `}
  ${(props) => {
    if (props.variant === catgVariants.HEADER) {
      return css`
        margin: 0;
      `
    }
    return null
  }}
`

export const CategoryBoxWrapperLi = styled.li`
  padding: 0;
  padding-left: 1.5rem;
  margin: 0.5rem 0;
`

export const CategoryChevron = styled.div<IconProps>`
  display: flex;
  align-items: center;
  ${(props) => {
    return props.hasNoChildren
      ? css`
          width: 1rem;
        `
      : ''
  }}
`
export const CategoryMinus = styled.div`
  display: none;
  align-items: center;
`
export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${ColorsMap.shadowGrey};
  position: absolute;
  bottom: 0;
`

