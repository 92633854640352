import styled from 'styled-components'
import { device, ColorsMap } from '@/utils/Theme'

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${ColorsMap.lightGrey};
`

export const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 2rem 7%;
  @media (max-width: ${device.tablet}) {
    padding: 2rem 8.5%;
    flex-direction: column;
    align-items: center;
  }
`

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 10rem;
  margin-right: 3%;
  margin-bottom: 1rem;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 1rem;
  margin-bottom: 2rem;
  max-width: 23rem;
  color: ${ColorsMap.black};

  &:nth-child(1) {
    margin-left: 1rem;
  }

  &:nth-child(3) {
    margin: 0;
    margin-left: 1rem;
    min-width: 25rem;

    @media (max-width: ${device.mobile}) {
      min-width: 0rem;
    }
  }

  @media (max-width: ${device.tablet}) {
    max-width: 100%;
  }

  @media (max-width: ${device.mobile}) {
    min-width: 21.5rem;
    margin: 1rem;
  }
`

export const Info = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  a {
    color: ${ColorsMap.black};

    &:hover {
      color: ${ColorsMap.brownishGrey};
    }
  }
`

export const EndFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 5.5rem;
  background-color: ${ColorsMap.primary};
  align-items: center;
  padding: 2rem 7%;
`

