import { retrieve } from '@/utils/Helpers'

export const categoryFilterAction = ({ payload, categoryID }) => {
  return {
    type: 'CATEGORY_FILTER',
    payload,
    categoryID,
  }
}
export const brandFilterAction = ({ payload, brandID }) => {
  return {
    type: 'BRAND_FILTER',
    payload,
    brandID,
  }
}

export const productPrintAction = ({ payload }) => {
  return {
    type: 'GET_PRODUCTS',
    payload,
  }
}
export const productClearAction = () => {
  return {
    type: 'CLEAR_PRODUCTS',
  }
}

export const getProducts = (options = null) => {
  let params = []
  params.push('limit=12')
  if (options) {
    if (options.category) {
      params.push(`category_id=${options.category}`)
    }
    if (options.brand) {
      params.push(`brand_id=${options.brand}`)
    }
    if (options.page) {
      params.push(`offset=${(options.page - 1) * 12}`)
    }
    if (options.search) {
      params.push(`search=${options.search}`)
    }
    if (options.random) {
      params.push(`random=1`)
    }
  }
  return (dispatch) => {
    dispatch(productClearAction())
    return retrieve(
      `api/product${params && `?${params.join('&')}`}`,
      'GET',
      options.token
    )
      .then((res) => res.json())
      .then((res) => {
        dispatch(
          productPrintAction({
            payload: res,
          })
        )
      })
  }
}
