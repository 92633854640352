import Link from 'next/link'
import React from 'react'
import { Anchor, LogoImg } from './HeaderStyles'

type HeaderBrandLinkProps = {
  brand: string
  width?: string
  logoSrc: string
  logoWidth: string
}

const HeaderBrandLink: React.VFC<HeaderBrandLinkProps> = ({ brand, width, logoSrc, logoWidth }) => {
  return (
    <Link as={`/brand/${brand}/`} href="/brand/[brand]/" passHref>
      <Anchor width={width} mx="auto" p="0 15px">
        <LogoImg width={logoWidth} src={logoSrc} hover />
      </Anchor>
    </Link>
  )
}

export default HeaderBrandLink
