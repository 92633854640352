import React, { useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components/macro'
import OutsideClickHandler from 'react-outside-click-handler'
import { LayoutProps, SpaceProps, space, layout } from 'styled-system'
import { ColorsMap, BorderRadiusMap } from '../../utils/Theme'

const ButtonVariant = {
  primary: 'primary',
  default: 'default',
}
export interface DropDownButtonProps extends LayoutProps, SpaceProps {
  buttonName?: React.ReactNode
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  variant: keyof typeof ButtonVariant
}

const Wrapper = styled.div<Pick<DropDownButtonProps, 'variant'>>`
  position: relative;
  display: inline-flex;
  align-items: center;
  background: ${({ variant }) => (variant == ButtonVariant.primary ? ColorsMap[variant] : 'none')};
  ${layout}
  ${space}
    
    > div {
      height: 100%;
  }
`

const InnerWrapper = styled.div`
  position: relative;
    height: 100%;
`

const ReferenceButton = styled.button<Pick<DropDownButtonProps, 'variant'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ variant }) => (variant == ButtonVariant.primary ? ColorsMap.white : ColorsMap.textDefault)};
    height: 100%;
  ${layout}
`

type DropDownProps = { isOpen?: boolean } & Pick<DropDownButtonProps, 'variant'>
const DropDown = styled.div<DropDownProps>`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  background: ${({ variant }) => (variant == ButtonVariant.primary ? ColorsMap[variant] : 'none')};
  color: ${({ variant }) => (variant == ButtonVariant.primary ? ColorsMap.white : ColorsMap.textDefault)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  z-index: 1;
`

const IconWrapper = styled.div`
  position: relative;
  border-radius: ${BorderRadiusMap.circle};
  margin: auto 0;
  overflow: hidden;
`

export const Icon = styled.img`
  width: 1.75rem;
  height: 1.75rem;
`

export const Separator = styled.div<SpaceProps>`
  width: 80%;
  margin: 0.25rem auto;
  border-top: 1px solid ${ColorsMap.white};
  opacity: 0.5;
  ${space}
`

export const DropDownButton: React.FC<DropDownButtonProps> = ({
  buttonName,
  leftIcon,
  rightIcon,
  variant = 'primary',
  children,
  ...props
}) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  })

  return (
    <Wrapper variant={variant} {...props}>
      <OutsideClickHandler className='sasdsa' style={{ height: '100%' }} onOutsideClick={() => setIsOpen(false)}>
        <InnerWrapper>
          <ReferenceButton
            type="button"
            ref={setReferenceElement}
            onClick={() => setIsOpen(!isOpen)}
            variant={variant}
            {...props}
          >
            <IconWrapper>{leftIcon}</IconWrapper>
            {buttonName}
            {rightIcon}
          </ReferenceButton>
          {children && (
            <DropDown
              variant={variant}
              isOpen={isOpen}
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              {...props}
            >
              {children}
            </DropDown>
          )}
        </InnerWrapper>
      </OutsideClickHandler>
    </Wrapper>
  )
}

