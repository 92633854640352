import React from 'react'
import styled from 'styled-components/macro'
import { flex, FlexProps, space, SpaceProps, layout, LayoutProps } from 'styled-system'
import { StyledIcon as StyledIconType } from '@styled-icons/styled-icon'
import {
  Bookmark,
  Check2,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  ChevronDoubleLeft,
  ChevronDoubleRight,
  PersonCircle,
  X,
  ThreeDots,
  Search,
} from '@styled-icons/bootstrap'
import { ShoppingCart } from '@styled-icons/entypo/ShoppingCart'
import { UserCircle } from '@styled-icons/boxicons-regular/UserCircle'
import { Globe2 } from '@styled-icons/bootstrap/Globe2'
import { ChevronDown } from '@styled-icons/boxicons-solid/ChevronDown'
import { Phone } from '@styled-icons/fa-solid/Phone'
import { Envelope } from '@styled-icons/fa-solid/Envelope'
import { Add } from '@styled-icons/fluentui-system-filled/Add'
import { DotSingle } from '@styled-icons/entypo/DotSingle'
import { Heart } from '@styled-icons/boxicons-regular/Heart'
import { Minus } from '@styled-icons/boxicons-regular/Minus'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { Percent } from '@styled-icons/bootstrap/Percent'
import { AngleRight } from '@styled-icons/fa-solid/AngleRight'
import { FacebookSquare } from '@styled-icons/fa-brands/FacebookSquare'
import { TwitterSquare } from '@styled-icons/fa-brands/TwitterSquare'
import { InstagramSquare } from '@styled-icons/fa-brands/InstagramSquare'

export const IconSize = {
  s: '0.75rem',
  m: '1rem',
  l: '1.25rem',
  xl: '1.625rem',
  xxl: '2.25rem',
  xxxl: '4rem',
}
export interface IconProps extends FlexProps, LayoutProps, SpaceProps {
  className?: string
  size?: keyof typeof IconSize
  color?: string
}
const IconProperty = {
  SIZE: 'm',
  COLOR: 'default',
}
const createIcon = (Icon: StyledIconType) => {
  return ({
    className,
    size = IconProperty.SIZE as keyof typeof IconSize,
    color = IconProperty.COLOR,
    ...rest
  }: IconProps) => {
    const StyledIcon = styled(Icon).attrs((props) => {
      return { ...props, ...rest }
    })`
      ${flex}
      ${space}
      ${layout}
    `
    return <StyledIcon className={className} size={IconSize[size]} color={color} />
  }
}

export default {
  Bookmark: createIcon(Bookmark),
  Check: createIcon(Check2),
  ChevronUp: createIcon(ChevronUp),
  ChevronDown: createIcon(ChevronDown),
  ChevronLeft: createIcon(ChevronLeft),
  ChevronRight: createIcon(ChevronRight),
  DoubleChevronLeft: createIcon(ChevronDoubleLeft),
  DoubleChevronRight: createIcon(ChevronDoubleRight),
  PersonCircle: createIcon(PersonCircle),
  Search: createIcon(Search),
  ShoppingCart: createIcon(ShoppingCart),
  UserCircle: createIcon(UserCircle),
  Globe: createIcon(Globe2),
  Add: createIcon(Add),
  DotSingle: createIcon(DotSingle),
  ThreeDots: createIcon(ThreeDots),
  Heart: createIcon(Heart),
  Minus: createIcon(Minus),
  CloseOutline: createIcon(CloseOutline),
  Percent: createIcon(Percent),
  AngleRight: createIcon(AngleRight),
  X: createIcon(X),
  Facebook: createIcon(FacebookSquare),
  Instagram: createIcon(InstagramSquare),
  Phone: createIcon(Phone),
  Envelope: createIcon(Envelope),
}
