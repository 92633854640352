import React from 'react'
import Head from 'next/head'
import styled, { ThemeProvider } from 'styled-components/macro'
import { Theme } from 'styled-system'

import { ColorsMap, ThemeConfig } from '@/utils/Theme'

import { Header } from '@/components/PageParts/Header'
import { Footer } from '@/components/PageParts/Footer'

type PageLayoutProps = {
  title: string
  description?: string
  profile?: boolean
  secondary?: boolean
  HeaderbackgroundGrey?: boolean
}

const PageLayout = ({
  title,
  description,
  profile,
  secondary,
  HeaderbackgroundGrey,
  children,
}: React.PropsWithChildren<PageLayoutProps>) => {
  const theme: Theme = ThemeConfig

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
      </Head>
      <div className="page" style={{ background: secondary ? ColorsMap.background : 'initial' }}>
        <Header backgroundGrey={HeaderbackgroundGrey} profileMenu={profile} />
        <Wrapper>{children}</Wrapper>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default PageLayout

const Wrapper = styled.div`
  padding-bottom: 3rem;
`

