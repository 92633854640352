import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { useRouter } from 'next/router'

import { ColorsMap, device } from '@/utils/Theme'

export interface BurgerMenuProps {
  menuState?: boolean
  follower?: React.ReactNode
  mainButtons?: React.ReactNode
}

const StyledBurger = styled.div<BurgerMenuProps>`
  ${(props) =>
    props.menuState
      ? css`
          position: fixed;
          top: 1.95rem;
          left: 8.5%;
        `
      : null}
  cursor: pointer;
  z-index: 2;
`
const BurgerMenuWrapper = styled.div<BurgerMenuProps>`
  width: 50%;
  height: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${ColorsMap.primary};
  display: none;
  z-index: 1;
  animation: mainFadeIn 2s forwards;
  ${(props) =>
    props.menuState
      ? css`
          display: initial;
        `
      : null};
  @media (max-width: ${device.mobile}) {
    width: 75%;
  }
  @media (max-width: ${device.mobileS}) {
    width: 100%;
  }
  @keyframes mainFadeIn {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const BurgerPart = styled.div<BurgerMenuProps>`
  width: 2.2rem;
  height: 0.2rem;
  border-radius: 5rem;
  background-color: ${(props) => (props.menuState ? ColorsMap.white : ColorsMap.primary)};
  margin-bottom: 0.4rem;
  transition: all 1s ease;

  &:nth-child(1) {
    ${(props) =>
      props.menuState
        ? css`
            transform: rotate(-45deg) translate(0, 0.85rem);
          `
        : null}
  }

  &:nth-child(2) {
    ${(props) =>
      props.menuState
        ? css`
            transform: translate(-30px, 0);
            background-color: transparent;
          `
        : null}
  }

  &:nth-child(3) {
    margin-bottom: 0;
    ${(props) =>
      props.menuState
        ? css`
            transform: rotate(45deg) translate(0, -0.85rem);
          `
        : null}
  }
`
const StyledSlideMenu = styled.div<BurgerMenuProps>`
  position: fixed;
  overflow-y: scroll;
  overflow-x: none;

  ::-webkit-scrollbar {
    width: 0;
  }

  padding: 5rem 0;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: ${ColorsMap.primary};
  transition: all 0.5s ease;

  @media (max-width: ${device.mobile}) {
    width: 75%;
  }

  @media (max-width: ${device.mobileS}) {
    width: 100%;
  }

  transform: ${(props) => (props.menuState ? 'translate(0, 0)' : 'translate(-101%, 0)')};
`

const Shadow = styled.div<BurgerMenuProps>`
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding-top: 5rem;
  background-color: black;
  opacity: 0.4;
  ${(props) =>
    props.menuState
      ? css``
      : css`
          display: none;
        `}
`
const FollowerWrapper = styled.div`
  position: absolute;
  top: -0.6rem;
  right: 0;
`

export const BurgerMenu: React.FC<BurgerMenuProps> = ({ follower, mainButtons, children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const router = useRouter()

  useEffect(() => {
    setIsOpen(false)
  }, [router.query])

  return (
    <>
      <Shadow onClick={() => setIsOpen(false)} menuState={isOpen} />
      <BurgerMenuWrapper menuState={isOpen}>{mainButtons}</BurgerMenuWrapper>
      <StyledSlideMenu menuState={isOpen}>{children}</StyledSlideMenu>
      <StyledBurger onClick={() => setIsOpen(!isOpen)} menuState={isOpen}>
        <BurgerPart menuState={isOpen} />
        <BurgerPart menuState={isOpen} />
        <BurgerPart menuState={isOpen} />
      </StyledBurger>
      {!isOpen && follower ? <FollowerWrapper>{follower}</FollowerWrapper> : ''}
    </>
  )
}
