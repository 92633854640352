import React from 'react'
import { SpaceProps, LayoutProps } from 'styled-system'

import { StyledButton } from './ButtonStyles'

export type VariantType = 'default' | 'horizontalRad' | 'verticalRad'
export type ColorVariantType = 'primary' | 'secondary' | 'white' | 'transparent' | 'primaryWhite' | 'defaultWhite'

export interface ButtonProps extends SpaceProps, LayoutProps {
  as?: any
  variant?: VariantType
  colorVariant?: ColorVariantType
  contentAlign?: string
  borderNone?: boolean
  type?: string
  uppercase?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent) => any
}

export const Button: React.FC<ButtonProps> = React.forwardRef(({ children, ...props }, ref) => (
  <StyledButton {...props} ref={ref}>
    {children}
  </StyledButton>
))

