import styled, { css } from 'styled-components/macro'
import { device, ColorsMap } from '../../../utils/Theme'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.25rem;
  @media (max-width: ${device.tablet}) {
    justify-content: flex-end;
  }
  @media (max-width: ${device.mobile}) {
    flex-direction: column;
  }
`
export const MainPart = styled.div`
  display: flex;
  flex-direction: column;

  &:nth-child(1) {
    margin-right: auto;
    @media (max-width: ${device.mobile}) {
      margin-right: 0;
    }
  }

  &:nth-child(3) {
    margin-right: 1rem;
  }
  @media (max-width: ${device.mobile}) {
    margin-bottom: 2rem;
  }
`
export const Line = styled.div`
  margin: 0 4%;
  width: 2px;
  background-color: ${ColorsMap.shadowGrey};
  @media (max-width: ${device.tablet}) {
    display: none;
  }
`
export const Separator = styled.div`
  width: 95%;
  height: 2px;
  background-color: ${ColorsMap.shadowGrey};
  margin-bottom: 4rem;
`
export const Name = styled.span`
  color: ${ColorsMap.primary};
`
export const DynamicName = styled.span`
  @media (max-width: ${device.tablet}) {
    display: none;
  }
  @media (max-width: ${device.mobile}) {
    display: initial;
  }
`
