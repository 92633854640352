import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { connect, useDispatch } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import _ from 'lodash'

import Icons from '@/components/Icons'
import { ProductCount } from '@/components/ProductCount'
import { Button } from '@/components/Button'
import { SignInBox } from '@/components/SignInBox'
import { ProfileMenu } from '@/components/ProfilePageParts'
import { ColorsMap } from '@/utils/Theme'
import { Text } from '@/components/Text'
import {
  HeaderWrapper,
  BrandMenu,
  WrapperButtons,
  UpperPart,
  Logo,
  BurgerWrapper,
  InputTextWrapper as InputTextForm,
  Brands,
  MainButtonDisplay,
  BurgerButtonsWrapper,
  GOLFDropdownWrapper,
  MainButton,
  SignInBoxWrapper,
  SignInBoxBackground,
  Close,
  CategoriesWrapper,
  ProfileMenuWrapper,
  BrandMenuInnerWrapper,
  LogoImg,
  BurgerInCartWrapper,
  LanguageImg,
  BurgerContent,
  HeaderFixed,
  Anchor, EShopButton, GolfDropDownIconWrapper,
} from './HeaderStyles'
import { InputText } from '../../InputText'
import { DropDownButton, Separator } from '../../DropDownButton'
import { BurgerMenu } from '@/components/BurgerMenu'
import { Categories } from '@/components/Categories'
import { Loader } from '@/components/Loader'

import { cartInitial, getUserData, setSearchString } from '../../../redux/actions'

import { Cookie } from '@/utils/Helpers'
import { StateInterface } from '@/types/State'
import HeaderBrandLink from './HeaderBrandLink'

export interface HeaderProps {
  state: StateInterface
  signInBoxProp?: boolean
  categories?: boolean
  profileMenu?: boolean
  backgroundGrey?: boolean
}

const Header = (props: HeaderProps) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()

  const [showSignInBox, setShowSignInBox] = useState<boolean>(false)
  const [isLogged, setIsLogged] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getUserData({ token: Cookie.text('token') }))
  }, [])

  useEffect(() => {
    setShowSignInBox(false)
  }, [isLogged])

  useEffect(() => {
    setIsLogged(props.state.userData.first_name != null)
    dispatch(cartInitial())
  }, [props.state.userData])

  useEffect(() => {
    if (props.state.categoryPrint.length > 0 && hasCategories === false) {
      setHasCategories(true)
    } else {
      setHasCategories(false)
    }
  }, [props.state.categoryPrint])

  const cartItemsCount = () => {
    if (props.state.cart && props.state.cart.quantity > 0) {
      return <ProductCount>{props.state.cart.quantity}</ProductCount>
    }
    return null
  }

  const { brand, category, ...query } = router.query

  const brandQuery = parseInt(brand as string)
  const categoryQuery = parseInt(category as string)

  const [hasCategories, setHasCategories] = useState<boolean>(false)

  const userButton = () => {
    if (isLogged) {
      return (
        <Link href="/profile/orders" passHref>
          <Button as="a" variant="default" height="2rem" px="0.25rem">
            <Icons.UserCircle size="xl" mr="3px" color={ColorsMap.primary} />
            <MainButtonDisplay>
              {props.state.userData.first_name || <Loader mx="1rem" width="1rem" height="1rem" />}
            </MainButtonDisplay>
          </Button>
        </Link>
      )
    }

    return (
      <DropDownButton
        height="2rem"
        variant="default"
        buttonName={<MainButtonDisplay>{t('sign-in')}</MainButtonDisplay>}
        leftIcon={<Icons.UserCircle size="xl" color={ColorsMap.primary} mr="3px" />}
      >
        <SignInBox variant="header" />
      </DropDownButton>
    )
  }

  const [search, setSearch] = useState<string | string[]>(query.search)

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (router.pathname != '/products') {
      router.push('/products?search=' + search)
    } else {
      const { page, category, ...excludedQuery } = router.query
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...excludedQuery,
            search,
          },
        },
        undefined,
        { shallow: true }
      )
    }
  }

  return (
    <>
      {/* <HeaderInfo>
        <strong>{t('header-title-sale')} &nbsp;&nbsp;&nbsp;</strong>
        {t('header-description-sale')}
      </HeaderInfo> */}
      <HeaderWrapper id="header">
        <HeaderFixed />
        <UpperPart backgroundGrey={props.backgroundGrey}>
          <Logo>
            <Link href="/" passHref>
              <Anchor>
                <LogoImg fluid src="/images/logo.svg" />
              </Anchor>
            </Link>
          </Logo>
          <BurgerWrapper categories={hasCategories}>
            <BurgerMenu
              mainButtons={
                <BurgerButtonsWrapper>
                  {isLogged ? (
                    <MainButton>
                      <Link href="/profile/orders" passHref>
                        <Anchor>
                          <Icons.UserCircle size="xl" color={ColorsMap.white} />
                        </Anchor>
                      </Link>
                    </MainButton>
                  ) : (
                    <MainButton>
                      <Button
                        onClick={() => {
                          setShowSignInBox(!showSignInBox)
                        }}
                      >
                        <Icons.UserCircle size="xl" color={ColorsMap.white} />
                      </Button>
                    </MainButton>
                  )}
                  <MainButton>
                    <Link href="/" locale="en" passHref>
                      <Anchor>
                        <LanguageImg src="/images/flags/en.svg" alt="" />
                      </Anchor>
                    </Link>
                    <Link href="/" locale="sk" passHref>
                      <Anchor>
                        <LanguageImg src="/images/flags/sk.svg" alt="" />
                      </Anchor>
                    </Link>
                  </MainButton>
                </BurgerButtonsWrapper>
              }
            >
              {/* TEMP */}
              <div style={{ padding: '0 2rem' }}>
                <EShopButton href="https://sintrasporteshop.sk/">
                  Do E-Shopu
                </EShopButton>
              </div>
              <CategoriesWrapper categories={hasCategories}>
                <div style={{ padding: '0 3rem', marginBottom: '2rem' }}>
                  <Link href="/special-offer" passHref>
                    <Text as="a" textColor="white" fontWeight="bold">
                      {t('title:clubs-offer')}
                    </Text>
                  </Link>
                </div>
                <Categories categories={props.state.categoryPrint} variant="header" />
              </CategoriesWrapper>
              <ProfileMenuWrapper profileMenu={props.profileMenu}>
                <ProfileMenu variant="header" />
              </ProfileMenuWrapper>
              <Brands>
                <BurgerContent>
                  <Link as="/brand/hummel/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" mt="1rem">
                      <Button height="5rem" width="100%">
                        <LogoImg width="10rem" src="/images/logos/hummel-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                  <Link as="/brand/pure-2-improve/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" mt="1rem">
                      <Button height="5rem" width="100%">
                        <LogoImg width="10rem" src="/images/logos/pure-2-improve-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                </BurgerContent>
                <BurgerContent>
                  <Link as="/brand/luhta/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" mt="1rem">
                      <Button height="5rem" width="100%">
                        <LogoImg width="10rem" src="/images/logos/luhta-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                  <Link as="/brand/icepeak/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" mt="1rem">
                      <Button height="5rem" width="100%">
                        <LogoImg width="10rem" src="/images/logos/icepeak-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                </BurgerContent>
                <BurgerContent>
                  <Link as="/brand/rukka/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" mt="1rem">
                      <Button height="5rem" width="100%">
                        <LogoImg width="10rem" src="/images/logos/rukka-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                  <Link as="/brand/rider/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" mt="1rem">
                      <Button height="5rem" width="100%">
                        <LogoImg width="10rem" src="/images/logos/rider-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                </BurgerContent>
                <BurgerContent>
                  <Link as="/brand/elite-sport/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" mt="1rem">
                      <Button height="5rem" width="100%">
                        <LogoImg width="10rem" src="/images/logos/elite-sport-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                </BurgerContent>
              </Brands>
              <Brands>
                <BurgerContent>
                  <Text m="1rem auto" textColor={ColorsMap.white} fontSize="xl" fontWeight="bold">
                    GOLF
                  </Text>
                </BurgerContent>
                <BurgerContent>
                  <Link as="/brand/cobra-puma-golf/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" mt="1rem">
                      <Button width="10rem">
                        <LogoImg fluid src="/images/logos/cobra-puma-golf-w.png" />
                      </Button>
                    </Anchor>
                  </Link>
                  <Link as="/brand/clicgear/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" my="auto">
                      <Button as="a" width="10rem">
                        <LogoImg fluid src="/images/logos/clicgear-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                </BurgerContent>
                <Separator my="1rem" />
                <BurgerContent>
                  <Link as="/brand/rovic/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem">
                      <Button width="10rem">
                        <LogoImg fluid src="/images/logos/rovic-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                  <Link as="/brand/volvik/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" mb="1rem">
                      <Button width="10rem">
                        <LogoImg fluid src="/images/logos/volvik-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                </BurgerContent>
                <Separator my="1rem" />
                <BurgerContent>
                  <Link as="/brand/lignum-tee/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem">
                      <Button width="10rem">
                        <LogoImg fluid src="/images/logos/lignum-tee-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                  <Link as="/brand/axglo/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem">
                      <Button width="10rem">
                        <LogoImg fluid src="/images/logos/axglo-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                </BurgerContent>
                <Separator my="1rem" />
                <BurgerContent>
                  <Link as="/brand/one/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem">
                      <Button width="10rem">
                        <LogoImg fluid src="/images/logos/one-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                  <Link as="/brand/sniper-golf/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" mt="0.75rem">
                      <Button width="10rem">
                        <LogoImg fluid src="/images/logos/sniper-golf-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                </BurgerContent>
                <Separator my="1rem" />
                <BurgerContent>
                  <Link as="/brand/pure-2-improve-golf/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem" mt="1.5rem">
                      <Button width="10rem">
                        <LogoImg fluid src="/images/logos/pure-2-improve-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                  <Link as="/brand/spalding/" href="/brand/[brand]/" passHref>
                    <Anchor mx="1rem">
                      <Button width="10rem">
                        <LogoImg fluid src="/images/logos/spalding-w.svg" />
                      </Button>
                    </Anchor>
                  </Link>
                </BurgerContent>
              </Brands>
            </BurgerMenu>
          </BurgerWrapper>
          <WrapperButtons>
            <MainButton>{userButton()}</MainButton>
            <MainButton>
              <Link href="/" locale="en" passHref>
                <Anchor>
                  <LanguageImg src="/images/flags/en.svg" alt="" />
                </Anchor>
              </Link>
              <Link href="/" locale="sk" passHref>
                <Anchor>
                  <LanguageImg src="/images/flags/sk.svg" alt="" />
                </Anchor>
              </Link>
            </MainButton>
          </WrapperButtons>
        </UpperPart>
        <BrandMenu>
          <BrandMenuInnerWrapper>
            <HeaderBrandLink brand="hummel" logoSrc="/images/logos/hummel.svg" logoWidth="110px" />
            <HeaderBrandLink brand="pure-2-improve" logoSrc="/images/logos/pure-2-improve.svg" logoWidth="150px" />
            <HeaderBrandLink brand="luhta" logoSrc="/images/logos/luhta.svg" logoWidth="167px" />
            <HeaderBrandLink brand="icepeak" logoSrc="/images/logos/icepeak.svg" logoWidth="160px" />
            <EShopButton href="https://sintrasporteshop.sk/">
              E-Shop
            </EShopButton>
            <DropDownButton
              variant="primary"
              buttonName={
                <Text as="span" fontSize="l" fontWeight="extrabold">
                  GOLF
                </Text>
              }
              rightIcon={<GolfDropDownIconWrapper><Icons.ChevronDown size="l" /></GolfDropDownIconWrapper>}
              width="8rem"
              height="100%"
              m="auto"
              mx="1rem"
            >
              <GOLFDropdownWrapper>
                <Link as="/brand/cobra-puma-golf/" href="/brand/[brand]/" passHref>
                  <Anchor>
                    <Button mx="1rem" mt="1rem">
                      <LogoImg fluid src="/images/logos/cobra-puma-golf-w.png" />
                    </Button>
                  </Anchor>
                </Link>
                <Separator my="1rem" />
                <Link as="/brand/clicgear/" href="/brand/[brand]/" passHref>
                  <Anchor>
                    <Button mx="1rem">
                      <LogoImg fluid src="/images/logos/clicgear-w.svg" />
                    </Button>
                  </Anchor>
                </Link>
                <Separator my="1rem" />
                <Link as="/brand/rovic/" href="/brand/[brand]/" passHref>
                  <Anchor>
                    <Button mx="1rem">
                      <LogoImg fluid src="/images/logos/rovic-w.svg" />
                    </Button>
                  </Anchor>
                </Link>
                <Separator my="1rem" />
                <Link as="/brand/volvik/" href="/brand/[brand]/" passHref>
                  <Button mx="1rem" mb="1rem">
                    <LogoImg fluid src="/images/logos/volvik-w.svg" />
                  </Button>
                </Link>
                <Separator my="1rem" />
                <Link as="/brand/lignum-tee/" href="/brand/[brand]/" passHref>
                  <Anchor>
                    <Button mx="1rem">
                      <LogoImg fluid src="/images/logos/lignum-tee-w.svg" />
                    </Button>
                  </Anchor>
                </Link>
                <Separator my="1rem" />
                <Link as="/brand/axglo/" href="/brand/[brand]/" passHref>
                  <Anchor>
                    <Button mx="1rem">
                      <LogoImg fluid src="/images/logos/axglo-w.svg" />
                    </Button>
                  </Anchor>
                </Link>
                <Separator my="1rem" />
                <Link as="/brand/one/" href="/brand/[brand]/" passHref>
                  <Anchor>
                    <Button mx="1rem" my="auto">
                      <LogoImg fluid src="/images/logos/one-w.svg" />
                    </Button>
                  </Anchor>
                </Link>
                <Separator my="1rem" />
                <Link as="/brand/sniper-golf/" href="/brand/[brand]/" passHref>
                  <Anchor>
                    <Button mx="1rem">
                      <LogoImg fluid src="/images/logos/sniper-golf-w.svg" />
                    </Button>
                  </Anchor>
                </Link>
                <Separator my="1rem" />
                <Link as="/brand/pure-2-improve-golf/" href="/brand/[brand]/" passHref>
                  <Anchor>
                    <Button mx="1rem">
                      <LogoImg fluid src="/images/logos/pure-2-improve-w.svg" />
                    </Button>
                  </Anchor>
                </Link>

                <Separator my="1rem" />
                <Link as="/brand/spalding/" href="/brand/[brand]/" passHref>
                  <Anchor>
                    <Button mx="1rem">
                      <LogoImg fluid src="/images/logos/spalding-w.svg" />
                    </Button>
                  </Anchor>
                </Link>
              </GOLFDropdownWrapper>
            </DropDownButton>
            <HeaderBrandLink brand="rukka" width="164px" logoSrc="/images/logos/rukka.svg" logoWidth="114px" />
            <HeaderBrandLink brand="rider" width="205px" logoSrc="/images/logos/rider.svg" logoWidth="155px" />
            <HeaderBrandLink
              brand="elite-sport"
              width="185px"
              logoSrc="/images/logos/elite-sport.svg"
              logoWidth="165px"
            />
          </BrandMenuInnerWrapper>
        </BrandMenu>
      </HeaderWrapper>
      {/* <HeaderInfo mobileOnly={true}>
        <strong>{t('header-title-sale')}</strong>
        <br />
        {t('header-description-sale')}
      </HeaderInfo> */}
      <SignInBoxWrapper show={showSignInBox}>
        <SignInBox variant="floated" />
        <Close
          onClick={() => {
            setShowSignInBox(false)
          }}
        >
          +
        </Close>
      </SignInBoxWrapper>
      <SignInBoxBackground
        onClick={() => {
          setShowSignInBox(false)
        }}
        show={showSignInBox}
      />
    </>
  )
}

const mapStateToProps = (state: StateInterface) => ({
  state,
})

export default connect(mapStateToProps)(Header)

